/* Table styling */
table {
  width: 45%;
  border-collapse: collapse;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 30%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  color: #333;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

/* Details row styling */
.details-row {
  background-color: #f8f8f8;
}

.details-row td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.details-content {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 5px 0;
}
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-left: 3%;
  margin-top: -3%;
}

.close:hover,
.close:focus,
.view-details:hover {
  color: blue;
  text-decoration: none;
  cursor: pointer;
  scale: 1.03;
}

.order-status-bar {
  margin: 0 !important;
  padding: 0 !important;
}
