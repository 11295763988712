

.home h1 {
  cursor: pointer;
}

.Frame {
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%; 
  min-height: 30%;
  max-height: 80%; 
  background-color: rgb(179, 177, 177);
  z-index: 10; 
  border: 1px solid #000;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px; 
  overflow: auto;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  max-width: 50%;
  height: 80%;
  flex-grow: 1;
  text-align: center;
  margin-right: 2vw;
  background-color: white;
  margin: auto;
  border: 1px solid #000;
  border-radius: 20px;
  margin-top: 2%;
  margin-bottom: 5%;
}

.styledInput {
  margin: 10px 0 15px 15px; 
  padding: 8px 15px; 
  border-radius: 5px; 
  border: 1px solid #ccc; 
  width: 100%; 
  max-width: 300px; 
}

.sendButton {
  margin: 10px auto;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: block;
  width: fit-content;
}

.CardPreview {
  width: 20vw;
  height: 60vh; 
  background-color: white; 
  margin-right: 10px; 
  padding: 10px; 
  overflow: auto; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #000; 
  border-radius: 20px;
}

.imagePreview {
  width: 100%; 
  height: 70%; 
  object-fit: fill;
}

.CardPreview div {
  margin-bottom: 5px; 
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  height: 40%; 
  width: 100%; 
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}

.cartButton,
.buyButton {
  padding: 8px 15px; 
  margin: 5px;
  border-radius: 5px; 
  border: 1px solid #ccc;
  cursor: pointer;
}

.styledTextarea {
  width: 100%;
  max-width: 700px; 
  min-height: 100px;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 10px 0;
  resize: vertical;
}

.BackButton {
  position: fixed;
  bottom: 100px;
  right: 30px;
  border: none;
  padding: 0;
}
.BackButton a {
  color: white;
  padding: 0px;
  font-size: 45px;
  text-decoration: none !important;
  padding: 0;
}
