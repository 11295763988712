.footer_section {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex-direction: column;
  background-color: #161b22 !important;
}
.footer_section h2 {
  font-size: 21px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 52px;
  color: rgba(204, 183, 91, 0.76);
}
.line {
  width: 90%;
  color: #4d4d4d;
  border-bottom: 1px solid;
}
